
import firebase from 'firebase/app';
import 'firebase/firestore';


// firebase init
const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDos-7CEXnKcuwZSs7E6VhBwEKRmEXk5NI",
    authDomain: "posts-4730a.firebaseapp.com",
    projectId: "posts-4730a",
    storageBucket: "posts-4730a.appspot.com",
    messagingSenderId: "674176473301",
    appId: "1:674176473301:web:b2b053162ef26bf8366a5c"
});
export const db = firebaseApp.firestore();
