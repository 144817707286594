import Vue from 'vue'
import App from './App.vue'
//import VueGoodTablePlugin from 'vue-good-table';
//import 'vue-good-table/dist/vue-good-table.css';

//import firebase from 'firebase';
import VueFirestore from 'vue-firestore';

import vuetify from './plugins/vuetify';
//Vue.use(firebase);
Vue.use(VueFirestore);
//Vue.use(VueGoodTablePlugin);

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')

