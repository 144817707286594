<template>
  <v-card>
    <v-card-title class = "vamo">
      PCI DSS v3.2.1 and AWS Audit Manager
 <v-card-spacer></v-card-spacer>
      <v-card-text class = "vamo">
        <p>The following table maps PCI DSS controls to AWS Audit Manager automated controls. Please note that: </p>  
        <ul>
          <li>PCI controls that are deemed to be manual, were not included.</li>
          <li>Column 'AWS Service': Identifies the AWS service the particular control applies to. You will only want to focus on the AWS services your company subscribes to.</li>
          <li>Column 'Control Keyword': Provides the keyword as specified by AWS. To view AWS documentation associated with the given control, just search the web for the given keyword.</li>
          <li>Column 'Control Description': Control description as provided by AWS documentation.</li>
          <li>Column 'Source': The AWS service leveraged to gather the evidence in question.</li>
          <li>The information in this table was last compiled on: January 29, 2021</li>

<li>Need help getting your AWS environment ready for an audit? Contact us at: <a href="mailto:sales@cabataba.com">sales@cabataba.com</a></li>
        </ul>
      </v-card-text>

      <v-text-field class = "vamo"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table class = "vamo"
      :headers="headers"
      :items="valores"
      :search="search"
      :items-per-page="100"
    multi-sort
    ></v-data-table>
  </v-card>
</template>

<script>

import { db } from './firebase';
  export default {
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'PCI_Req.',
  //          align: 'start',
            sortable: true,
            value: 'ReqNbr',
          },
          { text: 'AWS Service', value: 'AWS Service' },
          { text: 'Control Keyword', value: 'Control Keyword' },
          { text: 'Control Description', value: 'Control Description' },
          { text: 'Source', value: 'source' },
        ],
        valores: [],

    }},
    firestore() { return {valores: db.collection('PCI_AWS3').orderBy('ReqNbr')}}

  }


</script>

<style>
  .vamo {font-family: "Roboto" !Important;}
</style>